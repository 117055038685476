<template>
	<div class="ResourceAllocationDirectory" ref="ResourceAllocationDirectory">
		<el-row class="distribution_top">
			<el-row class='distribution_top_title'>
				<h3 style="font-size: 14px; font-weight: bold;">添加资源</h3>
			</el-row>
			<el-row>
				<el-col :span='2'>
					<span style="height: 40px; line-height: 40px; text-align: center; display: block;">工程分类</span>
				</el-col>
				<el-col :span="4">
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-col>
				<el-col :span="3">
					<el-button @click='btn_add_list'>新建工程目录</el-button>
				</el-col>
				<el-col style="width: 381px;" :offset='8'>
					<el-input placeholder="搜索模型,动画和脚本名称/ID/关键字"></el-input>
				</el-col>
			</el-row>
		</el-row>
		<el-row class="distribution_from">
			<el-col :span="7">
				<div ref='title' style="margin-right:52px; display: flex;">
					<p class="distribution_from_p" @click="moxin_radio">我的上传</p>
					<p class="distribution_from_p" @click="gomai_radio">我的购买</p>
					<p class="distribution_from_p" @click="shoucang_radio">我的收藏</p>
				</div>
			</el-col>
			<el-col :span="6">
				<el-radio-group v-model="radio" @change="radio_group_tow">
					<el-radio :label="null">全部</el-radio>
					<el-radio :label="1">模型</el-radio>
					<el-radio :label="2">动画</el-radio>
					<el-radio :label="3">脚本</el-radio>
				</el-radio-group>
			</el-col>
		</el-row>
		<el-row class="distribution_main">
			<el-col style="width: calc(20% - 16px); cursor: pointer;" ref='box' class="box" v-for="(item,index) in data">
				<div @click='click_box(item,index)' ref='list_data' :data-name='item.id'>
					<el-row>
						<!-- <el-checkbox></el-checkbox> -->
					</el-row>
					<div class="active_box">
					</div>
					<el-row>
						<div style="width: 100%;overflow: hidden;height: 185px; ">
							<img :src="common.retun_img(item.thumbnail)" alt="" style="width: 100%;">
						</div>
					</el-row>
					<el-row style="background-color: #f8f8f8;padding: 0 7px; ">
						<el-col style="" class="box_data">
							<span style="color: #000000; font-size: 16px;" class="text_over">{{item.name}}</span>
							<span>{{item.account_id}}</span>
						</el-col>
						<el-col class="box_data" style="border-bottom: none;">
							<span>ID:{{item.id}}</span>
							<span>大小：{{(item.file_size/1024/1024).toFixed(1)}}MB</span>
						</el-col>
					</el-row>
				</div>
			</el-col>
		</el-row>
		<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :current-page="currentPage"
		 :page-size='pagesize' :total='allpage' style='margin-left: calc(40%);margin-top: 20px;'>
		</el-pagination>
		<el-row>
			<div class="btn">
				<el-button @click='determine'>确定</el-button>
				<el-button @click='fanhui'>返回</el-button>
			</div>
		</el-row>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="432px">
			<div style="display: flex;">
				<span style="width: 100px;line-height: 40px; text-align: center;">工程名称</span>
				<el-input v-model="btn_add_data"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" class="dialog_btn">取 消</el-button>
				<el-button type="primary" @click="btn_add_list_determine" class="dialog_btn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	//请求数据
	import {
		requestdirectory_list,
		requestdirectory_add,
		requestaccount_project_list,
		requestaccount_account_list,
		account_project_add_list,
		requestRecords,
		requestaccount_account_share_list
	} from "../../api/request.js";
	export default {
		data() {
			return {
				options: [],
				value: '',
				radio: null,
				dialogVisible: false,
				btn_add_data: '',
				onelevel_list: [],
				checkList: 1,
				onelevel_data: [],
				data: [],
				towlevel_list: [],
				radio_text: '上传',
				pagesize: 0,
				allpage: 0,
				page: 0,
				currentPage: 0,
				default_id:null
			};
		},
		components: {},
		methods: {
			btn_add_list() {
				this.dialogVisible = true;
			},
			radio_group_tow() {
				this.Reset();

				if (this.radio_text == '上传') {
					this.moxin_radio()
				} else if (this.radio_text == '收藏') {
					this.shoucang_radio()
				} else if (this.radio_text == '购买') {
					this.gomai_radio()
				}
			},
			handleCurrentChange(val) {
				// this.page=null;
				// this.page=val;
				this.Reset()
				if (this.radio_text == '上传') {
					this.moxin_radio(val)
				} else if (this.radio_text == '收藏') {
					this.shoucang_radio(val)
				} else if (this.radio_text == '购买') {
					this.gomai_radio(val)
				}
			},
			Reset() {
				console.log(this.$refs.list_data)
				if(this.$refs.list_data!=null){
					for (let i = 0; i < this.$refs.list_data.length; i++) {
						this.$refs.list_data[i].classList = ''
						this.$refs.box[i].$el.style.boxShadow = '0px 0px 6px #c4c4c4';
					}
				}
				
			},
			click_box(item, index) {
				// this.$refs.list_data[index].classList='list_data_active'
				if (this.$refs.list_data[index].classList.length == 0) {
					this.$refs.list_data[index].classList = 'list_data_active'
					this.$refs.box[index].$el.style.boxShadow = '0px 0px 6px 0px rgba(196,62,27,0.75)';
				} else {
					this.$refs.list_data[index].classList = ''
					this.$refs.box[index].$el.style.boxShadow = '0px 0px 6px #c4c4c4';
				}
			},
			click_active(index) {
				for (let i = 0; i < this.$refs.title.children.length; i++) {
					this.$refs.title.children[i].classList = 'distribution_from_p'
				}
				this.$refs.title.children[index].classList = 'text_brn_title distribution_from_p'
			},
			fanhui() {
				this.$router.push({
					path: "MyShow",
					query: {
						level_0: 3
					}
				});
			},
			determine() {
				console.log()
				if (this.value == '') {
					this.$message({
						message: "请选择目录",
						type: "warning",
					});
					return
				}
				let data_index = []
				for (let i = 0; i < this.$refs.list_data.length; i++) {
					if (this.$refs.list_data[i].classList == 'list_data_active') {
						data_index.push({
							node_id: parseInt(this.value.node_id),
							resource_id: parseInt(this.$refs.list_data[i].dataset.name)
						})
					}
				}
				let data = {
					value_array: data_index
				}
				account_project_add_list(data).then(res => {
					this.$message({
						message: '添加资源成功',
						type: 'success',
						offset: 100
					});
				})
			},
			moxin_radio(val) {
				this.click_active(0)
				this.Reset()
				this.radio_text = '上传'
				let onedata = {
					state: 5,
					"is_animation": this.radio,
				}
				requestaccount_account_share_list(onedata, val).then(res => {
					this.data = res.ret.data;
					this.allpage = res.ret.total;
					this.pagesize = res.ret.per_page;
					this.currentPage = res.ret.current_page;
				})
			},
			gomai_radio(val) {
				this.click_active(1);
				this.Reset()
				this.radio_text = '购买'
				requestRecords(1, val, this.radio, ).then(res => {
					this.data = res.ret.data;
					this.allpage = res.ret.total;
					this.pagesize = res.ret.per_page;
					this.currentPage = res.ret.current_page;


				})
			},

			shoucang_radio(val) {
				this.click_active(2);
				this.Reset()
				this.radio_text = '收藏'
				let _this = this;
				let data = {
					"node_id_min": _this.towlevel_list.node_id_min,
					"node_id_max": _this.towlevel_list.node_id_max,
					"is_animation": this.radio,
					name: null
				}
				requestaccount_account_list(data, val).then(res => {
					this.data = res.ret.data;
					this.allpage = res.ret.total;
					this.pagesize = res.ret.per_page;
					this.currentPage = res.ret.current_page;
				})
			},
			btn_add_list_determine() {
				let _this = this;
				console.log(this.options);
				if (this.btn_add_data == '') {
					this.$message({
						message: "项目名称不能为空",
						type: "warning",
					});
					return
				}
				let max_sequence = 0;
				for (let h = 0; h < this.options.length; h++) {
					if (this.options[h].value.sequence > max_sequence) {
						max_sequence = this.options[h].value.sequence;
					}
					console.log(this.options[h].value.name, this.btn_add_data)
					if (this.options[h].value.name == this.btn_add_data) {
						this.$message({
							message: "不可添加重复文件夹名称",
							type: "warning",
						});
						return;
					}
				}

				console.log(sequence)
				let sequence = max_sequence + 1;
				let data = {
					"name": this.btn_add_data,
					"parent_id": this.onelevel_list.id,
					"level": 2,
					"sequence": sequence,
					"parent_node_id": this.onelevel_list.node_id
				}
				console.log(data)
				requestdirectory_add(data).then(res => {
					if (res.code == 0) {
						_this.Refresh_list();
						this.dialogVisible = false;
					}

				})
			},
			Refresh_list() {
				let _this = this;
				let datas = {
					parent_id: 0
				}
				requestdirectory_list(datas).then(res => {

					_this.onelevel_data = res.ret;

					for (let h = 0; h < res.ret.length; h++) {
						if (res.ret[h].level == 1 && res.ret[h].sequence == 1) {
							_this.onelevel_list = res.ret[h];
						} else if (res.ret[h].level == 1 && res.ret[h].sequence == 2) {
							_this.towlevel_list = res.ret[h];
						}
					}
					let data = {
						parent_id: _this.onelevel_list.id

					}
					requestdirectory_list(data).then(res => {
						_this.options = [];
						
						for (let i = 0; i < res.ret.length; i++) {
							_this.options.push({
								value: res.ret[i],
								label: res.ret[i].name
							})
							if(this.default_id!=null && this.default_id!='null'){
								if(this.default_id==res.ret[i].id){
									this.value=res.ret[i]
								}
							}
						};
						
					})

					let onedata = {
						state: 5,
						"is_animation": this.radio,
					}

					requestaccount_account_share_list(onedata).then(res => {
						console.log(res.ret.data)
						this.data = res.ret.data;
						this.allpage = res.ret.total;
						this.pagesize = res.ret.per_page;
						this.currentPage = res.ret.current_page;
					})
					this.$refs.title.children[0].classList = 'text_brn_title distribution_from_p'


				})
			}
		},
		mounted() {
			this.Refresh_list();
			console.log(this.$route.query.id)
			this.default_id=this.$route.query.id;
		},
	};
</script>
<style scoped>
	@import "../../style/common.css";

	.ResourceAllocationDirectory {
		width: 100%;
		background-color: #f8f8f8;
		padding-top: 20px;
	}

	.distribution_top {
		width: 1395px;
		height: 129px;
		background: white;
		box-shadow: 0px 0px 6px #c4c4c4;
		margin: 0 auto;
	}

	.distribution_top_title {
		height: 38px;
		line-height: 38px;
		margin-top: 12px;
		margin-left: 17px;
	}

	>>>.el-button {
		border: none;
		color: #c53e1e;
		background: white;
	}

	>>>.el-button:hover {
		background: white;
	}

	.distribution_from {
		width: 1395px;
		margin: 0 auto;
		height: 62px;
		line-height: 60px;
		margin-bottom: 20px;
	}

	.distribution_main {
		width: 1395px;
		margin: 0 auto;
	}

	.box {
		background: white;
		margin-right: 20px;
		border-radius: 10px;
		overflow: hidden;
		box-shadow: 0px 0px 6px #c4c4c4;
		margin-bottom: 20px;
		position: relative;
	}

	.box:nth-child(5n) {
		margin-right: 0px;
	}

	.box_data {
		height: 40px;
		line-height: 40px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CCCCCC;
		font-size: 14px;
		color: #a2a2a2;
	}

	>>>.dialog_btn {
		border: 1px solid #c53e1e;
		width: 120px;

	}

	>>>.el-dialog {
		border-radius: 10px;
	}

	>>>.dialog-footer {
		display: flex;
		padding-left: 40px;
		padding-right: 40px;
		justify-content: space-between;
	}

	>>>.el-button+.el-button {
		margin-left: 20px;
	}

	.distribution_from_p {
		display: block;
		cursor: pointer;
		width: 80px;
		margin-right: 10px;
		text-align: center;
		height: 30px;
		line-height: 30px;
		margin-top: 15px;
		font-size: 12px;
		border: 1px dotted #CCCCCC;
	}

	>>>.el-radio__input.is-checked+.el-radio__label {
		color: #c43e1b;
	}

	>>>.el-radio__input.is-checked .el-radio__inner {
		background: #c43e1b;

		border-color: #c43e1b;
	}

	.list_data_active>.active_box {
		display: block;
	}

	.active_box {
		width: 100%;
		height: 265px;
		/* background-color:rgba(0,0,0,0.3); */
		position: absolute;
		z-index: 1000;
		background: url(../../assets/img/active_img.svg) no-repeat center center;
		background-size: 38px;
		display: none;

	}

	>>>.btn .el-button {
		width: 120px;
		border: 1px solid #c43e1b;
	}

	.btn {
		margin: 25px auto;
		width: 300px;

	}

	.text_brn_title {
		color: #c43e1b;
		border-color: #c43e1b;
	}
	
</style>
